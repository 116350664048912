import React, { useState, useEffect } from 'react';  // Added useEffect for logging
import './App.css';
import PhotographerGrid from './components/PhotographerGrid';
import Lightbox from './components/Lightbox';

function App() {
  const [selectedPhotographer, setSelectedPhotographer] = useState(null);

  useEffect(() => {
    console.log("Selected Photographer State Updated:", JSON.stringify(selectedPhotographer, null, 2));
  }, [selectedPhotographer]);

  const handlePhotographerClick = (photographer) => {
    console.log("Photographer clicked:", photographer);
    setSelectedPhotographer(photographer);
  };

  const closeLightbox = () => {
    setSelectedPhotographer(null);
  };

  return (
    <div className="App">
      <h1>Mercedes Photographer Showcase</h1>
      <PhotographerGrid onPhotographerClick={handlePhotographerClick} />
      {selectedPhotographer && <Lightbox photographer={selectedPhotographer} onClose={closeLightbox} />}
    </div>
  );
}

export default App;
