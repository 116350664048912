import React from 'react';
import photographers from '../photographers.json';
import '../styles/PhotographerGrid.css';

const PhotographerGrid = ({ onPhotographerClick }) => {
  return (
    <div className="photographer-grid">
      {photographers.map((photographer, index) => (
        <div 
          key={index} 
          className="photographer-card" 
          onClick={() => {
            console.log("Card clicked for:", photographer.name);  // Debugging line
            onPhotographerClick(photographer);
          }}
        >
          <img src={`Photographers/${photographer.name}/${photographer.images[0]}`} alt={photographer.name} />
          <div className="photographer-name-overlay">
            <p>{photographer.name}</p>
            <p className="photographer-nationality">{photographer.nationality}</p>  {/* Display nationality */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotographerGrid;
