import React, { useState, useEffect } from 'react';  // Added useEffect for logging
import '../styles/Lightbox.css';

const Lightbox = ({ photographer, onClose }) => {
  const [currentSet, setCurrentSet] = useState(0);

  useEffect(() => {
    console.log("Received Photographer Prop in Lightbox:", JSON.stringify(photographer, null, 2));
    console.log("Current Set of Images to Display (State):", currentSet);
  }, [photographer, currentSet]);

  const handleImageClick = () => {
    setCurrentSet(currentSet + 4);
  };

  return (
    <div className="lightbox">
      <button style={{position: 'absolute', top: '10px', right: '10px', zIndex: '10000'}} onClick={onClose}>Close</button>
      <div className="lightbox-info">
        <p className="lightbox-name">{photographer.name}</p>
        <p className="lightbox-nationality">{photographer.nationality}</p>
      </div>
      {photographer.images.slice(currentSet, currentSet + 4).map((image, index) => (
        <div key={index} className="image-card" onClick={handleImageClick}>
          <img src={`./Photographers/${photographer.name}/${image}`} alt={image} />
        </div>
      ))}
    </div>
  );
};

export default Lightbox;
